import { useAuthenticatedUser, type User } from '@ifixit/auth-sdk';
import { SentryError } from '@ifixit/sentry';
import {
   type CartWarning,
   type DisplayableError,
   type LanguageCode,
   type StorefrontClient,
   useShopifyStorefrontClient,
} from '@ifixit/shopify-storefront-client';
import { useIsMutating, useQuery } from '@tanstack/react-query';
import { performAddToCart } from './use-add-to-cart';
import { useGetCustomerAccessToken } from '../use-get-customer-access-token';
import {
   clearStoredCartId,
   clearStoredCustomerAccessToken,
   getStoredCartId,
   updateCartId,
} from '../../helpers/storage';
import { getCart } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import { cartKeys } from '../../utils';
import { useTranslations } from '@ifixit/i18n';
import { CartMutationType, type Cart } from '../../types';
import { useToast } from '@chakra-ui/react';

export function useCart({ language }: { language: LanguageCode }) {
   const userQuery = useAuthenticatedUser();
   const getCustomerAccessToken = useGetCustomerAccessToken();
   const { client, currencyCode: fallbackCurrencyCode, storeCode } = useShopifyStorefrontClient();
   const isMutating = useIsMutating();

   const query = useQuery({
      queryKey: cartKeys.cart,
      enabled: !isMutating && !userQuery.isPlaceholderData,
      queryFn: async () => {
         const user = userQuery?.data ?? null;
         const cartId = getStoredCartId({ user, storeCode });
         const anonymousCartId = getStoredCartId({ user: null, storeCode });
         const existingCart = cartId ? await getCart(client, { cartId, language }) : null;
         const anonymousCart = anonymousCartId
            ? await getCart(client, { cartId: anonymousCartId, language })
            : null;
         if (anonymousCart && user) {
            let mergedCart = null;
            const userErrorsArr: DisplayableError[] = [];
            const validationErrorsArr: string[] = [];
            const warnings: CartWarning[] = [];

            if (existingCart) {
               const { cart, userErrors, validationErrors, warnings } = await performAddToCart({
                  cartId: existingCart.id,
                  client,
                  lines: buildIfixitCart({ cart: anonymousCart, fallbackCurrencyCode }).lineItems,
                  language,
               });
               mergedCart = cart ?? existingCart;
               userErrorsArr.push(...userErrors);
               validationErrorsArr.push(...validationErrors);
               warnings.push(...warnings);
            } else {
               const { cart, userErrors, validationErrors } = await updateCartBuyerIdentity({
                  client,
                  cartId: anonymousCart.id,
                  language,
                  getCustomerAccessToken,
                  storeCode,
                  user,
               });
               mergedCart = cart;
               userErrorsArr.push(...userErrors);
               validationErrorsArr.push(...validationErrors);
               warnings.push(...warnings);
            }

            if (mergedCart) {
               updateCartId({ cartId: mergedCart.id, storeCode, userId: user.id });
            }
            clearStoredCartId({ user: null, storeCode });

            return buildIfixitCart({
               cart: mergedCart,
               fallbackCurrencyCode,
               userErrors: userErrorsArr,
               validationErrors: validationErrorsArr,
               warnings,
            });
         }

         const emptyUserErrors: DisplayableError[] = [];
         const emptyValidationErrors: string[] = [];

         return buildIfixitCart({
            cart: existingCart,
            fallbackCurrencyCode,
            userErrors: emptyUserErrors,
            validationErrors: emptyValidationErrors,
         });
      },
   });
   return query;
}

async function updateCartBuyerIdentity({
   client,
   cartId,
   language,
   getCustomerAccessToken,
   storeCode,
   user,
}: {
   client: StorefrontClient;
   cartId: string;
   language: LanguageCode;
   getCustomerAccessToken: ReturnType<typeof useGetCustomerAccessToken>;
   storeCode: string;
   user: User;
}) {
   const customerAccessToken = await getCustomerAccessToken(user);
   let response = await client.updateCartBuyerIdentity({
      cartId,
      buyerIdentity: { customerAccessToken: customerAccessToken.accessToken },
      language,
   });
   if (
      response.cartBuyerIdentityUpdate?.cart &&
      response.cartBuyerIdentityUpdate.cart.buyerIdentity.customer == null
   ) {
      // If the token is invalid or expired, clear it from storage and retry.
      clearStoredCustomerAccessToken({ storeCode, user });
      const customerAccessToken = await getCustomerAccessToken(user);
      response = await client.updateCartBuyerIdentity({
         cartId,
         buyerIdentity: { customerAccessToken: customerAccessToken.accessToken },
         language,
      });
   }
   if (!response.cartBuyerIdentityUpdate?.cart) {
      throw new SentryError('Failed to update cart buyer identity', { extra: { cartId, user } });
   }
   return {
      cart: response.cartBuyerIdentityUpdate.cart,
      userErrors: response.cartBuyerIdentityUpdate.userErrors || [],
      validationErrors: [],
      warnings: response.cartBuyerIdentityUpdate.warnings || [],
   };
}

function useGetCartWarningDescription() {
   // https://shopify.dev/docs/api/storefront/2024-10/enums/CartWarningCode
   const t = useTranslations('ErrorHandling');
   return (CartWarning: CartWarning) => {
      switch (CartWarning.code) {
         case 'MERCHANDISE_NOT_ENOUGH_STOCK': {
            return t('notEnoughStock');
         }

         case 'MERCHANDISE_OUT_OF_STOCK': {
            return t('outOfStock');
         }

         // I don't think we will run into this case, but just in case
         case 'PAYMENTS_GIFT_CARDS_UNAVAILABLE':
         default: {
            return t('tryActionAgain');
         }
      }
   };
}

export function useCartToasts({ title }: { title: string }) {
   const t = useTranslations();
   const toast = useToast();
   const getCartWarningDescription = useGetCartWarningDescription();

   const showCartWarnings = ({ cart, type }: { cart: Cart; type: CartMutationType }) => {
      // Show a single toast for user errors
      if (cart.userErrors && cart.userErrors.length > 0) {
         toast({
            id: `${type}-user-error`,
            status: 'error',
            title,
            description: t('ErrorHandling.tryActionAgain'),
            isClosable: true,
            variant: 'subtle',
            position: 'bottom-right',
         });
      }

      // Show validation error toasts if there are any
      if (cart.validationErrors && cart.validationErrors.length > 0) {
         cart.validationErrors.forEach(error => {
            toast({
               id: `${type}-validation-error-${error}`,
               status: 'error',
               title: error,
               isClosable: true,
               variant: 'subtle',
               position: 'bottom-right',
            });
         });
      }

      // Show warnings toasts if there are any
      if (cart.warnings && cart.warnings.length > 0) {
         cart.warnings.forEach(warning => {
            toast({
               id: `${type}-warning-${warning.message}`,
               status: 'warning',
               title,
               description: getCartWarningDescription(warning),
               isClosable: true,
               variant: 'subtle',
               position: 'bottom-right',
            });
         });
      }
   };

   const showErrorToast = () => {
      toast.closeAll();
      toast({
         id: 'generic-cart-error',
         status: 'error',
         title,
         description: t('ErrorHandling.tryActionAgain'),
         isClosable: true,
         variant: 'subtle',
         position: 'bottom-right',
      });
   };

   return { showCartWarnings, showErrorToast };
}
