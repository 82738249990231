import type { Money, ProductVariantID } from '@ifixit/helpers';
import { ProductOemPartnership } from './models/cart';
import { CartWarning, type DisplayableError } from '@ifixit/shopify-storefront-client';

export enum CartMutationType {
   AddToCart = 'add-to-cart',
   UpdateLineItemQuantity = 'update-line-item-quantity',
   RemoveLineItem = 'remove-line-item',
}

export interface Cart {
   hasItemsInCart: boolean;
   isEmpty: boolean;
   lineItems: CartLineItem[];
   totals: {
      discount: Money<number> | null;
      itemsCount: number;
      price: Money;
      compareAtPrice?: Money | null | undefined;
   };
   checkoutUrl?: string;
   shopifyCartId?: string;
   userErrors?: DisplayableError[];
   validationErrors?: string[];
   warnings?: CartWarning[];
}

export interface CartLineItem {
   itemcode: string;
   variantTitle: string;
   shopifyVariantId: ProductVariantID;
   name: string;
   internalDisplayName?: string;
   imageSrc?: string | null;
   quantity: number;
   quantityAvailable?: number;
   price: Money;
   compareAtPrice?: Money | null;
   categories?: string[] | null;
   url: string;
   shopifyLineId?: string;
   fulfiller?: string;
   oemPartnership: ProductOemPartnership | null;
}
